import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation

function Footer() {
  return (
            <footer className="bg-white py-4">
      <div className="container mx-auto">
        <p className="text-center">&copy; 2024 Your Whooso. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
