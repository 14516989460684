import React, { useState, useEffect, useRef } from 'react';
import CountdownTimer from "../components/CountdownTimer";


    const openGoogleMaps = () => {
        // Your code to open Google Maps goes here
        // For example:
        window.open('https://maps.app.goo.gl/q76r7odWqKphK2fUA', '_blank');
    };

const WeddingLoremIpsum = () => {
    const [isVisible, setIsVisible] = useState(false);
    const flipContainerRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0 // Trigger when the element is fully visible
        };

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        if (flipContainerRef.current) {
            observer.observe(flipContainerRef.current);
        }

        return () => {
            if (flipContainerRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(flipContainerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const fadeContainer = document.querySelector('.fade-container');
        fadeContainer.classList.add('fade-in');
    }, []);


  return (

      <div className="relative">


          <div className="relative ">
              <img className="h-full w-full object-cover rounded-md mb-8  zoom-effect"
                   src="/images/lorem/text2.jpg" alt="Random image"/>

              <div className="absolute inset-0 flex flex-col items-center justify-start text-center text-white">
                  <h1 className="text-1xl font-bold  pt-10 mt-4"
                      style={{fontFamily: "'Alegreya', 'Alegreya Fallback' "}}>YOU ARE INVITED TO THE WEDDING OF</h1>
                  <p className="text-6xl italic text-shadow-black transition-transform duration-500 hover:scale-110"
                     style={{fontFamily: "'Alegreya', 'Alegreya Fallback'"}}>Lorem
                      & Ipsum</p>
              </div>
          </div>

          <div className="mb-8">
              <CountdownTimer/>
          </div>

          <div className="flex justify-center">
              <div>
                  <audio controls>
                      <source
                          src={""}
                          type="audio/mpeg"/>
                      Your browser does not support the audio element.
                  </audio>
              </div>
          </div>

          <div className="flex justify-center py-8 px-4 md:px-8 max-w-xl mx-auto">
              <div ref={flipContainerRef} className={`flip-container ${isVisible ? 'flip' : ''}`}>
                  <img src="/images/lorem/wedding.png" alt="Wedding Ring" className="w-24 md:w-24 flip-image"/>
              </div>
          </div>

          <div className="text-black text-center">
              <h1 className="text-2xl font-bold font-serif" style={{fontFamily: 'Alegreya'}}>Ceremony</h1>
              <p className="text-lg font-sans mb-2" style={{fontFamily: "'Work Sans', 'Work Sans Fallback'"}}>Puka
                  Shell Beach</p>
              <button onClick={openGoogleMaps}
                      className="bg-[#d6b89a] hover:bg-red-400 text-white py-2 px-4  rounded-md  text-lg font-semibold">View
                  Map
              </button>
          </div>

          <div className="flex justify-center py-8 px-4 md:px-8 max-w-xl mx-auto">
              <div className="fade-container">
                  <img src="/images/lorem/date.webp" alt="Wedding Ring" className="md:w-auto fade-image"/>
              </div>
          </div>

          <div className="text-black text-center">
              <h1 className="text-2xl font-bold font-serif" style={{fontFamily: 'Alegreya'}}>Reception</h1>
              <p className="text-lg font-sans mb-2" style={{fontFamily: "'Work Sans', 'Work Sans Fallback'"}}>Puka
                  Shell Beach</p>
              <button onClick={openGoogleMaps}
                      className="bg-[#d6b89a] hover:bg-red-400 text-white py-2 px-4  rounded-md  text-lg font-semibold ">View
                  Map
              </button>
          </div>

          <div className="flex justify-center py-8 px-4 md:px-8 max-w-xl mx-auto">
              <div ref={flipContainerRef} className={`flip-container ${isVisible ? 'flip' : ''}`}>
                  <img src="/images/lorem/wedding-dress.png" alt="Wedding Ring" className="w-24 md:w-24 flip-image"/>
              </div>
          </div>

          <div className="text-black text-center">
              <p className="text-2xl font-bold font-sans"
                 style={{fontFamily: "'Work Sans', 'Work Sans Fallback'"}}>Dress Code</p>
          </div>

          <div className="flex justify-center py-8 px-4 md:px-8 max-w-xl mx-auto">
              <div className="text-black text-center">
                  <img src="/images/lorem/girls.webp" alt="Wedding Ring" className="md:w-auto"/>
                  <p className="text-l font-sans"
                     style={{fontFamily: "'Work Sans', 'Work Sans Fallback'"}}>For the girls</p>
              </div>
          </div>

          <div className="flex justify-center py-8 px-4 md:px-8 max-w-xl mx-auto">
              <div className="text-black text-center">
                  <img src="/images/lorem/boys.webp" alt="Wedding Ring" className="md:w-auto"/>
                  <p className="text-l font-sans"
                     style={{fontFamily: "'Work Sans', 'Work Sans Fallback'"}}>For the boys</p>
                  <p className="text-xs font-sans"
                     style={{fontFamily: "'Work Sans', 'Work Sans Fallback'"}}>*Boys are requested NOT to wear polo
                      shirts.*</p>
              </div>
          </div>

          <div className="bg-[#FFEDE1] py-8 px-4 md:px-8 mb-8">
              <div ref={flipContainerRef}
                   className={`flip-container ${isVisible ? 'flip' : ''} flex justify-center py-8 px-4 md:px-8 max-w-xl mx-auto`}>
                  <img src="/images/lorem/camera.png" alt="Camera" className="w-24 md:w-24 flip-image"/>
              </div>
              <div className="gallery-header">
                  <p className="text-center font-semibold text-3xl ">snap and share</p>
                  <p className="text-center mb-8 text-2xl">#LoremFoundHisGreatestIpsum</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="photo-card overflow-hidden">
                          <img src="/images/lorem/prenup.jpg" alt="Prenup Photo" className="w-full rounded-xl"/>
                          <div className="container mx-auto text-center">
                              <h3 className="mt-2 mb-1 text-lg font-semibold">Prenup Photos</h3>
                              <button onClick={() => {
                                  window.location.href = 'http://localhost:3000/wedding/Invitation';
                              }}
                                      className="bg-[#d6b89a] hover:bg-[#555] text-white py-2 px-4 rounded-md text-lg font-semibold">See
                                  Full Gallery
                              </button>
                          </div>
                      </div>


                      <div className="photo-card overflow-hidden">
                          <img src="/images/lorem/weddingday.jpg" alt="Wedding Day Photo" className="w-full rounded-xl"/>
                          <div className="container mx-auto text-center">
                              <h3 className="mt-2 mb-1 text-lg font-semibold">Wedding Day Photos</h3>
                              <p className="text-gray-600">To Be Uploaded Here</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div className="center">
              <div className="text-center text-5xl">
                  <p className="text-3xl font-bold font-serif " style={{fontFamily: 'Bolivia Rosella Demo'}}>𝒯𝒽ℯ &nbsp;
                      <span className="text-2lg font-sans mb-2"
                            style={{fontFamily: "'Greyscale', 'Greyscale Fallback'"}}>𝓔𝓷𝓽𝓸𝓾𝓻𝓪𝓰𝓮</span></p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 ">
                  <div className="mx-2 my-2 md:mx-10 md:my-10">
                      <img className="max-w-full mb-4 md:mb-0 rounded-xl" src="/images/lorem/1.png"
                           alt="Entourage Image 1"/>
                  </div>
                  <div className="mx-2 my-2 md:mx-10 md:my-10 rounded ">
                      <img className="max-w-full mb-4 md:mb-0 rounded-xl" src="/images/lorem/2.png"
                           alt="Entourage Image 2"/>
                  </div>
              </div>
          </div>


          {/* Wedding Timeline Section */}
          <div className="center ">
              <div className="text-center mx-auto">
                  <img src="/images/lorem/timeline2.png" alt="Wedding Timeline" className="mx-auto"/>
              </div>
          </div>


      </div>

  );
};

export default WeddingLoremIpsum;