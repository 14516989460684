import React from 'react';
import BirthdayTimeline from "../components/BirthdayTimeline";
import BirthdayForm from "./BirthdayForm";


const BirthdayLorem = () => {

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        }
    };

    return (
        <div>
            {/* Welcome Section */}
            <div className="relative ">
                <div className="grid grid-cols-1 md:grid-cols-2 mb-8">
                    <div className="col-span-1 md:col-span-1 flex flex-col justify-center items-center">
                        <img className="w-full max-h-full" src="/images/birthday/birthday.jpg" alt="Entourage Image 1"/>
                        <div className="absolute inset-0 flex flex-col justify-center items-center">
                            <h2 className="text-3xl font-bold text-white">Hello friends!</h2>
                            <h2 className="text-3xl text-white">Lorem is turning 4</h2>
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-1 flex flex-col justify-center items-center">
                        <img className="w-full h-full md:h-auto" src="/images/birthday/birthday3.jpg"
                             alt="Entourage Image 2"/>
                        <div className="absolute inset-0 flex flex-col justify-center items-center">
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="mb-8 bg-cover bg-blend-multiply bg-[url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/text14-3.4.0.svg')] bg-no-repeat">
                <section className="py-16">
                    <div className="container mx-auto px-4">
                        <div className="text-center">
                            <p className="text-lg text-white font-bold mb-4">December 20th</p>
                            <h2 className="text-3xl font-bold text-white mb-4">You are invited to Lorem's 4th birthday
                                party</h2>
                            <a onClick={() => scrollToSection("RSVP")}
                               className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg uppercase hover:bg-orange-400">RSVP
                                Now</a>
                        </div>
                    </div>
                </section>
            </div>

            <div className="mb-8">
                <BirthdayTimeline url_image1="/images/birthday/birthday.jpg" url_image2="/images/birthday/birthday3.jpg"
                                  url_image3="/images/birthday/birthday3.jpg"/>
            </div>


            {/* Event Section */}
            <section id="RSVP" className="py-16">
                <div className="bg-white rounded-2xl container mx-auto px-4">
                    <div className="text-center">
                    <p className="text-lg text-gray-600 mb-2">Let's make Lorem smile.</p>
                        <h2 className="text-3xl font-bold text-gray-800 mb-4">RSVP here</h2>
                        <p className="text-lg text-gray-600 mb-8">We are getting well-prepared to welcome you. See you
                            soon!</p>
                        <p className="text-lg text-gray-600 mb-8">20.12.2024 | 4:00PM (GMT+04:00)</p>

                        <a onClick={() => scrollToSection("desired-section")}
                           className="inline-block px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg uppercase hover:bg-orange-400">RSVP
                            Now</a>
                    </div>
                </div>
            </section>

            <div id="desired-section">
                <BirthdayForm></BirthdayForm>
            </div>


            <section>
                {/* Desktop View */}
                <div className="hidden lg:flex">
                    <div className="w-1/2 px-4">
                        <div className="container mx-auto">
                            <div className="text-center">
                                <h1 className="text-3xl text-blue-800 mb-2">Venue location</h1>
                                <p className="text-justify text-xl">See you on December 30th at McDonals's Lancaster.
                                    The celebration will start at 4:00 pm. No worries
                                    if you're bringing your little child! The location offers all the equipment and
                                    areas
                                    you
                                    could need to put your baby to sleep or perform any other care procedure.</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2 px-4">
                        <div className="container mx-auto">
                            <div className="text-center">
                                <div className="map-container">
                                    <iframe
                                        title="Venue Map"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3864.475278099045!2d120.90252437577118!3d14.399743781961355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d3372ca4e2b9%3A0xf951102851095739!2sMcDonald&#39;s%20Lancaster!5e0!3m2!1sen!2suk!4v1715076165187!5m2!1sen!2suk"
                                        width="900"
                                        height="450"
                                        style={{border: 0}}
                                        allowFullScreen=""
                                        loading="lazy"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile View */}
                <div className="lg:hidden">
                    <div className="container mx-auto">
                        <div className="text-center mb-8">
                            <h1 className="text-3xl text-blue-800 mb-2">Venue location</h1>
                            <p className="text-justify text-xl mb-6">See you on December 30th at McDonals's Lancaster.
                                The celebration will start at 4:00 pm. No worries
                                if you're bringing your little child! The location offers all the equipment and areas
                                you
                                could need to put your baby to sleep or perform any other care procedure.</p>
                        </div>
                        <div className="map-container">
                            <iframe
                                title="Venue Map"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3864.475278099045!2d120.90252437577118!3d14.399743781961355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d3372ca4e2b9%3A0xf951102851095739!2sMcDonald&#39;s%20Lancaster!5e0!3m2!1sen!2suk!4v1715076165187!5m2!1sen!2suk"
                                width="400"
                                height="300"
                                style={{border: 0}}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
};

export default BirthdayLorem;
