import React, { useState, useEffect } from 'react';


const ZhaviaAvyannaPage = () => {
   const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = ["/images/inner_image1.png", "/images/inner_image2.png"];
    const fadeDuration = 500; // Duration of the fade effect in milliseconds

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(intervalId);
    }, [images.length]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            // Fade out the current image
            const currentImage = document.getElementById(`innerImage${currentImageIndex}`);
            if (currentImage) currentImage.style.opacity = "0";

            // Fade in the next image after the fadeDuration
            const nextIndex = (currentImageIndex + 1) % images.length;
            const nextImage = document.getElementById(`innerImage${nextIndex}`);
            if (nextImage) nextImage.style.opacity = "1";
        }, fadeDuration);

        return () => clearTimeout(timerId);
    }, [currentImageIndex]);

    return (
<div
  className="bg-cover bg-center"
  style={{
    backgroundImage: window.innerWidth <= 768
      ? "url('/images/birthday/zhavia-avyanna/zhavia-avyanna.gif')"
      : "url('/images/birthday/zhavia-avyanna/pc-background.gif')",
    backgroundSize: window.innerWidth <= 768 ? "cover" : "100% 100%"
  }}
>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl m-auto">

                 {/* left Column */}
                <div className="relative flex items-center justify-center ">
                <div className="flex flex-col items-center justify-center text-center h-screen">
                <h1 className="text-pink-800 text-3xl pt-10 italic shadow-outline-white">You Are Invited To</h1>
                <div className="text-black text-center">
                <div className="relative">
                <div className="max-w-sm 'opacity-100' : 'opacity-0 transition-opacity duration-1000'">
                <img src="/images/birthday/zhavia-avyanna/3.png"/>
                    </div>

                </div>


                </div>
                <div className="text-black italic">
                <p className=" text-3xl  transition-transform duration-500 hover:scale-110 text-blue-800 shadow-outline-white">Baptism & Birthday Party</p>
                <p className=" text-xl ">of</p>
      <h1 className="text-4xl font-bold text-pink-500 shadow-outline-white hover:scale-110 ">
        Zhavia Ayanna
      </h1>
                </div>
                 <div className="text-lg font-bold text-pink-800 shadow-outline-white mt-4 ">
                    <div >
                       June 8, 2024 10:00 AM
                    </div>
                         <div >
                        <p>Ugac Norte Reyes St. Tuguegarao City</p>
                    </div>
                </div>
                </div>
                </div>
                {/* Right Column */}
                <div className="relative flex items-center justify-center ">
                {/*<img className="absolute inset-0 w-full h-full object-cover rounded-md"*/}
                {/*     src="/images/birthday/PinkFlower.png" alt="Background image"/>*/}
                <div className="text-center text-white relative z-10 flex flex-col justify-center items-center ml-10 mr-10 ">
                <p className="text-xl text-black italic">We Cordially invited you to join the celebration to
                mark
                this special occasion</p>
                <form className="flex flex-col  w-80  md:mr-8 mt-8">
                {/* Name Field */}
                <label className="text-black text-lg  mb-2 italic" htmlFor="name">Name</label>
                <input className="rounded-md py-2 px-4 mb-4 w-full" type="text" id="name" name="name"
                   placeholder="Your name"/>

                {/* Email Field */}
                <label className="text-black text-lg mb-2 italic" htmlFor="email">Email</label>
                <input className="rounded-md py-2 px-4 mb-4 w-full" type="email" id="email" name="email"
                   placeholder="Your email"/>

                {/* Submit Button */}
                <button
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 w-full mb-8">RSVP
                </button>
                </form>
                </div>
                </div>

        </div>
        </div>
    )
        ;
};

export default ZhaviaAvyannaPage;
