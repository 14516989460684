import React, {useState} from 'react';

function BirthdayForm() {
    const [rsvp, setRsvp] = useState(''); // State to track RSVP selection
    const [additionalGuests, setAdditionalGuests] = useState([]); // State to track additional guests


    const handleRsvpChange = (e) => {
        const value = e.target.value;
        setRsvp(value);

        // If the RSVP is 'no', clear the additional guests array
        if (value === 'no') {
            setAdditionalGuests([]);
        }
    };

    const handleAdditionalGuestClick = () => {
        // Check if the number of additional guests is less than 2
        if (additionalGuests.length < 1) {
            setAdditionalGuests([...additionalGuests, {parentName: '', kidName: ''}]);
        }
    };

    const handleInputChange = (index, field, value) => {
        const updatedGuests = [...additionalGuests];
        updatedGuests[index][field] = value;
        setAdditionalGuests(updatedGuests);
    };

    const handleDeleteClick = (index) => {
        const updatedGuests = [...additionalGuests];
        updatedGuests.splice(index, 1);
        setAdditionalGuests(updatedGuests);
    };

    const handleSubmit = (index) => {
        const updatedGuests = [...additionalGuests];
        updatedGuests.splice(index, 1);
        setAdditionalGuests(updatedGuests);
    };


    return (
        <div className="bg-orange-400 flex flex-col items-center h-4/5 mt-8 mb-8">
            <div className="bg-blue-400 p-8 flex flex-col items-center w-full xl:w-3/4 rounded-xl mt-20 mb-20">
                <h1 className="text-2xl font-semibold mb-4">Will you join us for the birthday celebration?</h1>

                {/* Radio buttons for Yes and No */}
                <div className="mb-4 flex items-center">
                    <input type="radio" id="yes" name="rsvp" value="yes" className="mr-2 h-5 w-5"
                           onChange={handleRsvpChange}/>
                    <label htmlFor="yes" className="mr-4 text-lg">Yes</label>
                    <input type="radio" id="no" name="rsvp" value="no" className="mr-2 h-5 w-5"
                           onChange={handleRsvpChange}/>
                    <label htmlFor="no" className="text-lg">No</label>
                </div>

                <div className="flex flex-col md:flex-row w-full justify-between">
                    <div className="w-full md:w-1/2 pr-0 md:pr-4">
                        <div className="mb-4">
                            <label htmlFor="parentName" className="block text-black font-bold mb-2">Parent name</label>
                            <input type="text" id="parentName"
                                   className="border border-transparent rounded-full px-4 py-2 w-full"/>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 pl-0 md:pl-4">
                        <div className="mb-4">
                            <label htmlFor="kidName" className="block text-black font-bold mb-2 md:mb-1">Kid
                                name</label>
                            <input type="text" id="kidName"
                                   className="border border-gray-300 rounded-full px-4 py-2 w-full"/>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full justify-between">
                    <div className="w-full md:w-1/2 pr-0 md:pr-4">
                        <div className="mb-4">
                            <label htmlFor="parentName" className="block text-black font-bold mb-2">Email</label>
                            <input type="text" id="parentName"
                                   className="border border-transparent rounded-full px-4 py-2 w-full"/>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 pl-0 md:pl-4">
                        <div className="mb-4">
                            <label htmlFor="kidName" className="block text-black font-bold mb-2 md:mb-1">Phone
                                number</label>
                            <input type="text" id="kidName"
                                   className="border border-gray-300 rounded-full px-4 py-2 w-full"/>
                        </div>
                    </div>
                </div>

                {/* Additional guest button */}
                {rsvp === 'yes' && (
                    <button className="WM_GLOBAL_paragraph14 styled__AdditionalGuestButton-sc-nqpo2f-5 ffiTza"
                            onClick={handleAdditionalGuestClick}>+ Additional guest</button>
                )}

                {/* Additional form fields */}
                {additionalGuests.map((guest, index) => (
                    <div key={index} className="flex flex-col md:flex-row w-full justify-between">
                        <div className="w-full md:w-1/2 pr-0 md:pr-4">
                            <div className="mb-4">
                                <label htmlFor={`parentName${index}`} className="block text-black font-bold mb-2 ">Parent
                                    name</label>
                                <input type="text" id={`parentName${index}`}
                                       className="border border-transparent rounded-full px-4 py-2 w-full"
                                       value={guest.parentName}
                                       onChange={(e) => handleInputChange(index, 'parentName', e.target.value)}/>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 pl-0 md:pl-4">
                            <div className="mb-4">
                                <label htmlFor={`kidName${index}`} className="block text-black font-bold mb-2">Kid
                                    name</label>
                                <input type="text" id={`kidName${index}`}
                                       className="border border-gray-300 rounded-full px-4 py-2 w-full"
                                       value={guest.kidName}
                                       onChange={(e) => handleInputChange(index, 'kidName', e.target.value)}/>
                            </div>
                        </div>
                        <button className="text-red-500 " onClick={() => handleDeleteClick(index)}>
                            &#10006;
                        </button>

                    </div>
                ))}
                {/* Submit button */}
                <button className="bg-orange-500 text-white px-4 py-2 rounded-md mt-4" onClick={handleSubmit}>Submit
                </button>
            </div>
        </div>
    );
}

export default BirthdayForm;
