import React, { useState, useEffect } from 'react';
import '../index.css'; // Import CSS for transition styles

const ImageList = () => {
  // Array containing image filenames
  const imageNames = ['home', 'home1', 'home2'];

  // State to keep track of the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to handle the image change
  const changeImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  };

  // Effect to change the image automatically every 1.5 seconds
  useEffect(() => {
    const interval = setInterval(changeImage, 3000);
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="image-list">
      {/* Render the current image with transition effect */}
      <img
        src={`/images/${imageNames[currentImageIndex]}.png`} // Path relative to public directory
        alt={`Image ${currentImageIndex}`}
        className="image-item"
      />
    </div>
  );
};

export default ImageList;
