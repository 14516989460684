import React from 'react';
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactUsPage from './pages/ContactUsPage';
import Form from './pages/Form';
import WeddingInvitation from './pages/WeddingInvitation';
import WeddingLoremIpsum from "./pages/WeddingLoremIpsum";
import Header from './components/Header';
import Footer from './components/Footer';
import BirthdayLorem from "./pages/BirthdayLorem";
import BirthdayLoremv2 from "./pages/BirthdayLoremv2";
import BirthdayLoremv3 from "./pages/BirthdayLoremv3";
import ZhaviaAvyannaPage from "./pages/ZhaviaAvyannaPage";
import SamplePage from "./pages/SamplePage";
import Jaira from "./components/Jaira";

function App() {
    return (
        <Router>
            <AppContent/>
        </Router>
    );
}

function AppContent() {
    const location = useLocation();
    const isWeddingRoute = location.pathname.startsWith('/wedding');
    const isBirthdayRoute = location.pathname.startsWith('/birthday');

    return (
        <div className="flex flex-col min-h-screen ">
            {/* Render Header component conditionally */}
            {!isWeddingRoute && !isBirthdayRoute && <Header/>}
            <div className="flex-1">
                <Routes>
                    {/* Routes without header */}
                    <Route path="/wedding/*" element={<WeddingRoutes/>}/>
                    <Route path="/birthday/*" element={<BirthdayRoutes/>}/>
                    {/* Other routes with header */}
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/contactus" element={<ContactUsPage/>}/>
                    <Route path="/sample" element={<SamplePage/>}/>
                    <Route path="/form" element={<Form/>}/>
                </Routes>
            </div>
            <Footer/>
        </div>
    );
}

// Component for routes under /wedding without header
const WeddingRoutes = () => {
    return (
        <Routes>
            <Route path="johntest" element={<WeddingInvitation/>}/>
            <Route path="/lorem-ipsum" element={<WeddingLoremIpsum/>}/>
            {/* Add more wedding routes as needed */}
        </Routes>
    );
};

const BirthdayRoutes = () => {
    return (
        <Routes>
            <Route path="/lorem" element={<BirthdayLorem/>}/>
            <Route path="/loremv2" element={<BirthdayLoremv2/>}/>
            <Route path="/loremv3" element={<BirthdayLoremv3/>}/>
            <Route path="/jaira" element={<Jaira/>}/>
            <Route path="/zhaviaavyanna" element={<ZhaviaAvyannaPage/>}/>
            <Route path="/lorem-ipsum" element={<WeddingLoremIpsum/>}/>
            {/* Add more wedding routes as needed */}
        </Routes>
    );
};

export default App;
