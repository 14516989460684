import React, { useState } from 'react';

function AboutPage() {

    return (
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">

          {/* Mission */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
            <p className="text-gray-700">
          At Whooso, our mission is to transform the way individuals and organizations invite guests to celebrate special moments. We strive to provide intuitive, creative, and customizable solutions that enhance the excitement and anticipation leading up to any event. By combining technology with the art of celebration, we aim to make every invitation an unforgettable experience.    </p>
          </section>

          {/* Features */}
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Key Features</h2>
            <ul className="list-disc list-inside text-gray-700">
              <li>Personalize email invitations, video invitations, and card invitations</li>
              <li>Choose from a wide range of stunning templates</li>
              <li>Add photos, videos, and heartfelt messages</li>
              <li>Send invitations instantly to loved ones worldwide</li>
            </ul>
          </section>

          {/* Call to Action */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Join Us Today!</h2>
            <p className="text-gray-700">
              Join the Whooso community today and discover the joy of spreading love and happiness through beautifully crafted electronic invitations. Let Whooso be your partner in making every moment unforgettable!
            </p>
          </section>
        </div>
      </div>
    );
}

export default AboutPage;
