import React from 'react';
import FeatureCard from "../components/FeatureCard";
import { Link } from 'react-router-dom';
import ImageList from "../components/ImageList";

function HomePage() {
    return (
        <div className="min-h-screen flex flex-col ">
            <div className="container mx-auto px-4 py-6 ">
  <div className="flex flex-col sm:flex-row justify-center  ">
      <div className="px-4 py-8 ">
        {/* Content for the first div */}
      <h1 className="text-3xl font-bold mb-4">Go Digital with Whooso!</h1>
      <p className="mb-4">Planning an event? Simplify with digital invitations! Whether it's a birthday, baby shower, wedding, or business gathering, our digital invites have you covered.</p>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Why Choose Digital?</h2>
        <ul className="list-disc ml-4">
          <li className="mb-2"><strong>Simple:</strong> No printing, addressing, or mailing. Just pick a design, add details, and send via email or social media.</li>
          <li className="mb-2"><strong>Interactive:</strong> Include RSVP buttons, event countdowns, maps, videos, and more for fun engagement.</li>
          <li className="mb-2"><strong>Eco-Friendly:</strong> Save paper and the planet with digital invites.</li>
          <li className="mb-2"><strong>Customizable:</strong> Choose from templates, colors, fonts, and graphics to match your event.</li>
          <li className="mb-2"><strong>Easy Updates:</strong> Make changes effortlessly and keep guests informed.</li>
          <li className="mb-2"><strong>Affordable:</strong> Skip printing costs for a budget-friendly option.</li>
        </ul>
      </div>

      <p>Ready to simplify your event planning? Get started today!</p>
          <br/>
<div>
<a href="https://whooso.com/contactus"  rel="noopener noreferrer" className="bg-blue-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600">
  Create Your Digital Invitation Now!
</a>
    </div>
      </div>
      <div className="px-4 py-8">
        <ImageList />
      </div>
    </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center md:justify-items-stretch">
                    {/* Repeat the FeatureCard component for each card, passing in the appropriate props */}
                    <FeatureCard
                        icon="/path-to-your-icon.svg"
                        title="Silver Invitation"
                        text="Offers simple and elegant video invitations that can be personalized to match your event."
                         listItems={["Birthday Video Invitation","Greetings Video"]}
                    />
                    <FeatureCard
                        icon="/path-to-your-icon.svg"
                        title="Gold Invitation"
                        text="Take your invitations to the next level with advanced customization options. Add custom videos, voiceovers, and more to make your invitation truly unique."
                        listItems={["Birthday Video Invitation","Greetings Video","Wedding Video Invitation","Customize Template","Voice Over","1x Revisiton"]}
                    />
                    <FeatureCard
                        icon="/path-to-your-icon.svg"
                        title="Platinum"
                        text="Experience the ultimate invitation solution with web invitations and RSVP functionality. Manage your guest list effortlessly and keep track of RSVPs online."
                        listItems={["Website Invitation","Birthday Video Invitation","Greetings Video","Wedding Video Invitation","Customize Template","Voice Over","3x Revisiton"]}

                    />
                    {/* Add other FeatureCard components here */}
                </div>
            </div>
        </div>
    );
}

export default HomePage;


