import React from 'react';

const invitations = [
  {
    title: 'Birthday Invitation',
    image: 'https://via.placeholder.com/150?text=Birthday'
  },
  {
    title: 'Wedding Invitation',
    image: 'https://via.placeholder.com/150?text=Wedding'
  },
  {
    title: 'Baptism',
    image: 'https://via.placeholder.com/150?text=Baptism'
  }
];

const InvitationCard = ({ title, image }) => (
  <div className="p-4 max-w-xs mx-auto bg-white rounded-xl shadow-md space-y-4">
    <img className="w-full h-32 object-cover rounded-t-lg" src={image} alt={title} />
    <div className="text-center text-xl font-medium text-black">{title}</div>
  </div>
);

const InvitationsList = () => (
  <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-6">
    {invitations.map((invitation, index) => (
      <InvitationCard key={index} {...invitation} />
    ))}
  </div>
);

export default InvitationsList;
