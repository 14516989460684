import React from 'react';

const Form = () => {
  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md flex w-full max-w-3xl"> {/* Adjust the width of the form */}
        <div className="w-3/4"> {/* Adjust the width of the form content */}
          <h1 className="text-2xl font-semibold mb-4">New Entry</h1>
          <div className="mb-2"> {/* Adjust margin bottom */}
            <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name</label>
            <input type="text" id="name" className="border border-gray-300 rounded px-4 py-2 w-full" />
          </div>

          <div className="mb-2"> {/* Adjust margin bottom */}
            <label htmlFor="age" className="block text-gray-700 font-bold mb-1">Age (TURNING TO)</label>
            <input type="text" id="age" className="border border-gray-300 rounded px-4 py-2 w-full" />
          </div>

          <div className="mb-2"> {/* Adjust margin bottom */}
            <label htmlFor="bday" className="block text-gray-700 font-bold mb-2">Date</label>
            <input type="date" id="bday" className="border border-gray-300 rounded px-4 py-2 w-full" />
          </div>

          <div className="mb-2"> {/* Adjust margin bottom */}
            <label htmlFor="time" className="block text-gray-700 font-bold mb-2">Time</label>
            <input type="text" id="time" className="border border-gray-300 rounded px-4 py-2 w-full" />
          </div>

          <div className="mb-2"> {/* Adjust margin bottom */}
            <label htmlFor="venue" className="block text-gray-700 font-bold mb-2">Venue Address</label>
            <input type="text" id="venue" className="border border-gray-300 rounded px-4 py-2 w-full" />
          </div>

          <div className="mb-2"> {/* Adjust margin bottom */}
            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email</label>
            <input type="email" id="email" className="border border-gray-300 rounded px-4 py-2 w-full" />
          </div>

          <div className="mb-2"> {/* Adjust margin bottom */}
            <label htmlFor="mobile" className="block text-gray-700 font-bold mb-2">Mobile Number</label>
            <input type="text" id="mobile" className="border border-gray-300 rounded px-4 py-2 w-full" />
          </div>

          <div className="mb-2"> {/* Adjust margin bottom */}
            <label htmlFor="notes" className="block text-gray-700 font-bold mb-2">Notes</label>
            <textarea id="notes" className="border border-gray-300 rounded px-4 py-2 w-full"></textarea>
          </div>
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">Submit</button>
        </div>
        <div className="w-1/4 flex flex-col justify-around"> {/* Adjust the width of the image container */}
            <h1 className="text-2xl font-semibold mb-4">Design</h1>
          {/* Image 1 */}
          <input type="radio" id="image1" name="image" className="hidden" />
          <label htmlFor="image1" className=" border-gray-300 rounded p-2 cursor-pointer">
            <img src="/images/image1.png" alt="Image 1" width="100" height="100" />
          </label>

          {/* Image 2 */}
          <input type="radio" id="image2" name="image" className="hidden" />
          <label htmlFor="image2" className=" border-gray-300 rounded p-2 cursor-pointer">
            <img src="/images/home3.png" alt="Image 2" width="100" height="100" />
          </label>

          {/* Image 3 */}
          <input type="radio" id="image3" name="image" className="hidden" />
          <label htmlFor="image3" className=" border-gray-300 rounded p-2 cursor-pointer">
            <img src="/images/home2.png" alt="Image 3" width="100" height="100" />
          </label>

          {/* Image 4 */}
          <input type="radio" id="image4" name="image" className="hidden" />
          <label htmlFor="image4" className=" border-gray-300 rounded p-2 cursor-pointer">
            <img src="/images/ass.png" alt="Customize" width="100" height="100" />
          </label>

          {/* Add more images similarly */}
        </div>
      </div>
    </div>
  );
};

export default Form;
