import React from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import InvitationsList from "../components/InvitationsList";

function SamplePage() {

    return (
        <div className="max-w-7xl m-auto">
          <div className="App">
    <InvitationsList />
  </div>
        </div>
    );
}

export default SamplePage;
