import React, { useState } from 'react';

function FeatureCard({ icon, title, text, listItems }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center mb-4">
        {/* Icon would be an SVG or image tag */}
        <img src={icon} alt="" className="h-10" />
        <h3 className="text-lg font-semibold">{title}</h3>
      </div>
      <p className="text-gray-600 mb-6">{text}</p>
          {listItems && (
        <ul className="list-disc pl-6">
          {listItems.map((item, index) => (
            <li key={index} className="text-gray-600 mb-2">{item}</li>
          ))}
        </ul>
      )}

    </div>
  );
}

export default FeatureCard;

