import React from 'react';

const Birthday = () => {
    return (
        <div className="min-h-screen bg-gradient-to-r from-pink-200 to-pink-500 flex flex-col items-center justify-center p-6">
            <div className="bg-white p-10 rounded-lg shadow-lg text-center max-w-2xl w-full mb-6">
                <h1 className="text-4xl font-bold text-pink-600 mb-4">JOIN US TO CELEBRATE!</h1>
                <h2 className="text-3xl font-bold text-pink-700 mb-4">JAIRA'S BIRTHDAY</h2>
                <img
                    src="https://via.placeholder.com/150"
                    alt="Jaira"
                    className="rounded-full mx-auto mb-6"
                />
                <h3 className="text-2xl font-semibold text-pink-600 mb-4">JAIRA is turning 7TH</h3>
                <p className="text-xl font-medium mb-4">SATURDAY, JULY 27, 4:30 PM</p>
                <p className="text-xl font-medium mb-8">JOLLIBEE, MALABON GENERAL TRIAS, CAVITE</p>
            </div>

            <div className="bg-white p-10 rounded-lg shadow-lg text-center max-w-2xl w-full mb-6 border-2 border-pink-300">
                <h3 className="text-2xl font-semibold text-pink-600 mb-4">7 Balloons</h3>
                <ul className="text-lg font-medium text-pink-700 space-y-2">
                    <li className="flex items-center">
                        <span className="mr-2">🎈</span> Joaquin
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🎈</span> Jonel
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🎈</span> Kiefer
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🎈</span> Erin
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🎈</span> Sakura
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🎈</span> Bella
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🎈</span> Wilona
                    </li>
                </ul>
            </div>

            <div className="bg-white p-10 rounded-lg shadow-lg text-center max-w-2xl w-full mb-6 border-2 border-yellow-300">
                <h3 className="text-2xl font-semibold text-yellow-600 mb-4">7 Treasures</h3>
                <ul className="text-lg font-medium text-yellow-700 space-y-2">
                    <li className="flex items-center">
                        <span className="mr-2">💎</span> Ate Tzieny
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">💎</span> Jillian
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">💎</span> Nicka
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">💎</span> Jemaica
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">💎</span> Heurij
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">💎</span> Jannah
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">💎</span> Mama Jam
                    </li>
                </ul>
            </div>

            <div className="bg-white p-10 rounded-lg shadow-lg text-center max-w-2xl w-full mb-6 border-2 border-blue-300">
                <h3 className="text-2xl font-semibold text-blue-600 mb-4">7 Wishes</h3>
                <ul className="text-lg font-medium text-blue-700 space-y-2">
                    <li className="flex items-center">
                        <span className="mr-2">🌟</span> Ninang Neri
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌟</span> Ninang Jisel
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌟</span> Ate Chicklet
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌟</span> Bestfriend Angel
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌟</span> Ate Gen
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌟</span> Tita Khaycee
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌟</span> Mama Tet
                    </li>
                </ul>
            </div>

            <div className="bg-white p-10 rounded-lg shadow-lg text-center max-w-2xl w-full mb-6 border-2 border-red-300">
                <h3 className="text-2xl font-semibold text-red-600 mb-4">7 Roses</h3>
                <ul className="text-lg font-medium text-red-700 space-y-2">
                    <li className="flex items-center">
                        <span className="mr-2">🌹</span> Janus
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌹</span> Kuya Jas
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌹</span> Tito Dong
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌹</span> Papa Jeth
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌹</span> Kuya Bok
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌹</span> Kuya Jai
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">🌹</span> Papa Ryan
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Birthday;
