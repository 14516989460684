import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';

function ContactUsPage() {

    return (
      <div className="container mx-auto px-4 py-8">
           {/* Main content */}
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
  <section className="mb-4 mt-4">
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
  <div className="flex justify-center gap-4 p-4">
    <div className="text-center">
      <img src="/images/whooso-whatapps1.jpeg" alt="Whooso" className="max-h-44 rounded-lg" />
      <p className="mt-2">WhatsApp</p>
    </div>
    <div className="text-center">
      <img src="/images/whooso-line.jpeg" alt="Whooso" className="max-h-44 rounded-lg" />
      <p className="mt-2">Line</p>
    </div>
  </div>

            {/*<p className="text-gray-700 mb-2"><strong>Whatapps:</strong>+63 977 128 3181</p>*/}
          </section>
             {/* Social Media Links */}
        <section>
          <h2 className="text-1xl font-semibold mt-4 py-2">Follow Us on Social Media</h2>
          <div className="flex space-x-2">
            {/* Facebook */}
            <a href="https://www.facebook.com/profile.php?id=61558674480193" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 transition-colors duration-300">
                  <i className="fab fa-facebook-square fa-2x"></i>
            </a>
             {/* TikTok */}
            <a href="https://www.tiktok.com/@owhooso" target="_blank" rel="noopener noreferrer" className="text-black hover:text-gray-800 transition-colors duration-300">
              <i className="fab fa-tiktok fa-2x"></i>
            </a>
          </div>
        </section>
            <section className="mt-4">
               <p className="text-gray-700 mb-2"><strong>Email:</strong>whoosodigitalinvitation@gmail.com</p>
            </section>

        </div>
      </div>
      </div>
    );
}

export default ContactUsPage;
