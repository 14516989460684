import React from 'react';

function Header() {
  return (
    <header className="header" >
      <div className="container mx-auto flex justify-between items-center">
      <h1 className="text-4xl font-bold pad">
  <a href="/"> <img src="/images/whooso.png" alt="Whooso"className="h-20" /> </a>
</h1>
        <nav>
          <ul className="flex space-x-4">
            <li>
              <a href="/" className="hover:bg-pink-50 0 p-3 rounded">
                Home
              </a>
            </li>
  <li>
                <a href="/sample" className="hover:bg-pink-50 p-3 rounded">
                Sample
              </a>
            </li>
            <li>
              <a href="/about" className="hover:bg-pink-50 p-3 rounded">
                About
              </a>
            </li>

                <li>
                <a href="/contactus" className="hover:bg-pink-50 p-3 rounded">
                Contact
              </a>
            </li>


          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
