import React from 'react';
import CountdownTimer from "../components/CountdownTimer";
import AudioPlayer from "../components/AudioPlayer";

const WeddingInvitation = () => {
  return (
<div className="">
    <div className="absolute inset-0 flex mt-20 justify-center">
        <div className="text-white text-center ">
          <h1 className="text-lg font-bold text-shadow-black">YOU ARE INVITED TO THE WEDDING OF</h1>
          <p className="text-6xl italic text-shadow-black">Lheo & Kristel</p>
        </div>
    </div>
    <img src="/lheo/lheo_main.jpg" className="w-full h-auto object-cover" alt="Whooso"/>

       <div className="m-5">
        <AudioPlayer></AudioPlayer>
    </div>
    <div className="m-5">
        <CountdownTimer></CountdownTimer>
    </div>
        <div className="justify-center w-fit m-auto mt-10 text-center">
           <img src="/images/lorem/weddingring.png" alt="Image 1" width="100" height="100" />
            <div className="  mt-4 font-bold text-lg"> CEREMONY</div>
            <p className=" mt-4 "> St. Pancratius Chapel</p>
            <div className="bg-rose-300 rounded text-white font-bold px-4 py-2 mt-4">VIEW MAP</div>
    </div>

     <div className="justify-center w-fit m-auto mt-10 text-center">
           <img src="/images/lorem/weddingring.png" alt="Image 1" width="100" height="100" />
            <div className="  mt-4 font-bold text-lg"> RECEPTION</div>
            <p className=" mt-4 "> Maria's Village and Aquafarm</p>
            <div className="bg-rose-300 rounded text-white font-bold px-4 py-2 mt-4">VIEW MAP</div>
    </div>
  <div className="flex justify-center items-center flex-col w-fit mx-auto mt-10 text-center">
    <img src="/images/lorem/weddingring.png" alt="Image 1" className="w-16 h-16 mb-4" />
    <div className="font-bold text-lg mb-4">DRESS CODE</div>
    <p className="mb-4">Pastel Colors / Neutral Colors</p>
    <p className="mb-2">FOR THE GIRLS</p>
    <img src="/images/lorem/Color-Palette-Circles-Girls-1920w.webp" alt="Image 1" className="w-1/2 mb-4" />
    <p className="mb-2">FOR THE BOYS</p>
    <img src="/images/lorem/Color-Palette-Circles-Boys-1920w.webp" alt="Image 2" className="w-1/2" />
</div>

<div className="bg-gray-500">
    <div className="flex justify-center items-center flex-col w-fit mx-auto mt-10 text-center">
        <div>SNAP AND SHARE</div>
        <div>#JOCASFoundHisGreatestLOVE</div>

        <div className="flex justify-between w-full max-w-2xl mt-4">
            <div className="relative w-1/2">
                <img src="/images/lorem/prenup.jpg" alt="Image 1" className="w-full" />
                <div className="absolute inset-0 flex flex-col justify-center items-center">
                    <p className="text-white text-lg font-bold">Your Text Overlay 1</p>
                </div>
            </div>

            <div className="relative w-1/2">
                <img src="/images/lorem/weddingphoto.jpg" alt="Image 2" className="w-full" />
                <div className="absolute inset-0 flex flex-col justify-center items-center bg">
                    <p className="text-white text-lg font-bold">Your Text Overlay 2</p>
                </div>
            </div>
        </div>
    </div>
</div>



</div>

  );
};

export default WeddingInvitation;
