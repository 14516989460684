import React, { useState, useEffect, useRef } from 'react';

const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  // Ref for the audio element
  const audioRef = useRef(null);

  // Toggle play/pause
  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  // Update progress bar as audio plays
  useEffect(() => {
    const audio = audioRef.current;
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurrentTime(audio.currentTime);
    };

    const setAudioTime = () => setCurrentTime(audio.currentTime);

    audio.addEventListener('loadeddata', setAudioData);
    audio.addEventListener('timeupdate', setAudioTime);

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
    };
  }, []);

  // Update audio current time
  const onScrub = (value) => {
    audioRef.current.currentTime = value;
    setCurrentTime(value);
  };

  return (
      <div className="max-w-2xl px-6 m-auto">
    <div className="flex items-center space-x-4 bg-gray-100 p-4 max-w-2xl m-auto rounded-lg shadow">
      <audio ref={audioRef} src="path_to_your_audio_file.mp3" preload="metadata" />
      <button onClick={togglePlayPause} className="focus:outline-none">
        <svg
          className={`w-6 h-6 ${isPlaying ? 'text-blue-600' : 'text-gray-600'}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          {isPlaying ? (
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 12H6" />
          ) : (
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168L9 6v12l5.752-6.832z" />
          )}
        </svg>
      </button>
      <div className="flex-1">
        <input
          type="range"
          value={currentTime}
          step="1"
          min="0"
          max={duration || 0}
          className="w-full cursor-pointer"
          onChange={(e) => onScrub(e.target.value)}
          style={{
            backgroundSize: `${(currentTime / duration) * 100}% 100%`
          }}
        />
      </div>
      <div className="text-gray-600">
        {new Date(currentTime * 1000).toISOString().substr(14, 5)}
      </div>
      <div className="text-gray-600">
        -{new Date((duration - currentTime) * 1000).toISOString().substr(14, 5)}
      </div>
    </div>
          </div>
  );
};

export default AudioPlayer;
