import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {useState} from 'react';

function BirthdayTimeline({ url_image1, url_image2, url_image3 }) {
    const [currentSlide, setCurrentSlide] = useState(0);

    console.log(url_image1)
    console.log('__________')
    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    const renderButtons = () => {
        return (
            <div className="flex justify-center space-x-4 mt-4 mb-4">
                {[1, 2, 3].map((slideNumber) => (
                    <div key={slideNumber} className="relative flex flex-col items-center">
                        <button
                            className={`w-10 h-10 rounded-full flex items-center justify-center border border-gray-400 ${
                                currentSlide === slideNumber - 1 ? 'bg-orange-500 text-white' : 'bg-white text-gray-600'
                            }`}
                            onClick={() => handleSlideChange(slideNumber - 1)}
                        >
                            {slideNumber}
                        </button>
                        <span className="text-xs text-black mt-1">
            {slideNumber === 1 ? 'Welcome' : slideNumber === 2 ? 'Fun & Games' : "Cake's Here"}
          </span>
                    </div>
                ))}
            </div>

        );
    };

    return (
        <section className="bg-orange-400 py-5 ">
            <div className="container mx-auto px-4">
                <div className="text-center">
                    <h2 className="text-3xl font-bold text-gray-800 mb-4 mt-5">𝓑𝓲𝓻𝓽𝓱𝓭𝓪𝔂 𝓣𝓲𝓶𝓮𝓵𝓲𝓷𝓮</h2>
                </div>
                {renderButtons()}
                <Carousel
                    showArrows={true}
                    infiniteLoop={true}
                    selectedItem={currentSlide}
                    onChange={(index) => handleSlideChange(index)}
                    showThumbs={false} // Add this line to hide the images
                >
                    <div>
                        <img src={url_image1} alt="Birthday Slide 1"/>
                        <p className="legend">4:00 pm - We'll be impatiently waiting for you and your kids to join
                            us.</p>
                    </div>
                    <div>
                        <img src={url_image2} alt="Birthday Slide 2"/>
                        <p className="legend">4:00 pm - 9:00 pm - It will be all about having fun and enjoying games
                            both inside and outside.</p>
                    </div>
                    <div>
                        <img src={url_image3} alt="Birthday Slide 3"/>
                        <p className="legend">6:00 pm - Cake time! Let's not make the kids wait too long and cut the
                            birthday cake.</p>
                    </div>

                    {/* Add more slides as needed */}
                </Carousel>
            </div>
        </section>
    );
}

export default BirthdayTimeline;
