import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  // State for countdown
  const [timeLeft, setTimeLeft] = useState({
    days: 18,
    hours: 5,
    minutes: 32,
    seconds: 24,
  });

  useEffect(() => {
    // Timer countdown function
    const interval = setInterval(() => {
      // Update the countdown
      setTimeLeft((prevTime) => {
        const updatedTime = { ...prevTime };
        if (updatedTime.seconds > 0) {
          updatedTime.seconds--;
        } else {
          if (updatedTime.minutes > 0) {
            updatedTime.minutes--;
            updatedTime.seconds = 59;
          } else {
            if (updatedTime.hours > 0) {
              updatedTime.hours--;
              updatedTime.minutes = 59;
            } else {
              if (updatedTime.days > 0) {
                updatedTime.days--;
                updatedTime.hours = 23;
              } else {
                // Time is up!
                clearInterval(interval);
              }
            }
          }
        }
        return updatedTime;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex justify-center space-x-4">
      {Object.entries(timeLeft).map(([unit, value]) => (
        <div key={unit} className="flex flex-col items-center">
          <div className="flex items-center justify-center w-20 h-20 border-4 border-orange-200 rounded-full sm:w-24 sm:h-24">
            <p className="text-2xl font-semibold">{String(value).padStart(2, '0')}</p>
          </div>
          <p className="text-sm uppercase mt-2">{unit}</p>
        </div>
          //Jolly update
      ))}
    </div>
      //test update for git collab
  );
};

export default CountdownTimer;
