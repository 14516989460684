import React, { useState, useEffect } from "react";

const BirthdayLorem = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = ["/images/inner_image1.png", "/images/inner_image2.png"];
  const fadeDuration = 500; // Duration of the fade effect in milliseconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalId);
  }, [images.length]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      // Fade out the current image
      const currentImage = document.getElementById(
        `innerImage${currentImageIndex}`,
      );
      if (currentImage) currentImage.style.opacity = "0";

      // Fade in the next image after the fadeDuration
      const nextIndex = (currentImageIndex + 1) % images.length;
      const nextImage = document.getElementById(`innerImage${nextIndex}`);
      if (nextImage) nextImage.style.opacity = "1";
    }, fadeDuration);

    return () => clearTimeout(timerId);
  }, [currentImageIndex]);

  return (
    <div
      className="bg-cover bg-center"
      style={{
        backgroundImage:
          window.innerWidth <= 768
            ? "url('/images/birthday/PinkFlower.png')"
            : "url('/images/birthday/PinkBeauty.png')",
        backgroundSize: window.innerWidth <= 768 ? "cover" : "100% 100%",
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl m-auto">
        {/* Left Column */}
        <div className="relative flex items-center justify-center">
          <div className="flex flex-col items-center justify-center text-center h-screen">
            <h1
              className="text-black text-3xl pt-10 italic"

            >
              Join us for the
            </h1>
            <div className="text-black text-center">
              <div className="relative">
                {/* Inner images */}
                {images.map((imageUrl, index) => (
                  <img
                    key={index}
                    id={`innerImage${index}`}
                    className={`absolute inset-0 rounded-full ${index === 0 ? "opacity-100" : "opacity-0 transition-opacity duration-1000"}`}
                    src={"/images/birthday/cutebabies.png"}
                    alt={`Inner image ${index}`}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ))}
                {/* Outer image */}
                <img
                  className="h-96 w-80 object-cover zoom-effect opacity-100"
                  src="/images/birthday/Beige.png"
                  alt="Random image"
                  style={{ opacity: 0.9 }} // Adjust opacity here
                />
              </div>
            </div>
            <div className="text-black italic " >
              <p
                className="text-3xl transition-transform duration-500 hover:scale-110">
                Baptism
              </p>
              <p
                className="text-xl">
                of
              </p>
              <p
                className="text-3xl  transition-transform duration-500 hover:scale-110 mb-8">
                Meredith{" "}
              </p>
            </div>
            <div className="flex flex-col md:flex-row justify-between italic font-bold">
              <div className="w-full md:w-60 pr-0 md:pr-4 ">
                <div className="mb-4 ">March 25 at 3PM</div>
              </div>
              <div className="w-full md:w-60 pl-0 md:pl-4">
                <div className="mb-4 ">
                  <p>123 Anywhere st, Any City</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Right Column */}
        <div className="relative flex items-center justify-center text-black">
          <div className="text-center z-10 flex flex-col justify-center items-center italic">
            <p className="text-xl">
              We Cordially invited you to join the celebration to mark this
              special occasion
            </p>
            <form className="flex flex-col  w-80  md:mr-8 mt-8 text-lg">
              {/* Name Field */}
              <div  className="mb-2">
                Name
              </div>
              <input
                className="rounded-md py-2 px-4 mb-4 w-full"
                type="text"
                id="name"
                name="name"
                placeholder="Your name"
              />
              {/* Email Field */}
              <div className="mb-2">
                Email
              </div>
              <input
                className="rounded-md py-2 px-4 mb-4 w-full"
                type="email"
                id="email"
                name="email"
                placeholder="Your email"
              />
              {/* Submit Button */}
              <button className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 w-full mb-8">
                RSVP
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirthdayLorem;
